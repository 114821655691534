<template>
  <div class="port">
    <div class="l-col">
      <!--      <label class="typo__label standart-title port-down">{{ $t('label.type') }}</label>-->
      <base-select
        v-model="value"
        select-label=""
        :hide-selected="true"
        :searchable="false"
        :placeholder="$t('placeholder')"
        :options="subnet"
        :field-label="$t('label.type')"
        size="medium"
        :allow-empty="false"
        :preselect-first="true"
        class="new-disk__services"
        @input="onChange"
      >
      </base-select>
    </div>
    <div class="resize-row l-col">
      <label class="standart-text">{{
        $t('cost', { msg: $n(cost, 'currency', this.$i18n.locale) })
      }}</label>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'NewIp',
  components: { BaseSelect },
  mixins: [setFocus],
  props: {
    instance: {
      type: Array,
      default: () => [],
    },
    net: {
      type: String,
      default: '',
    },
    device: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    subnet() {
      return this.instance.map(x => x.name);
    },
    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    price() {
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === 'floating_ip')[this.dev]
        : null;
    },
    cost() {
      return this.price ? this.price : 0;
    },
  },
  methods: {
    onChange(event) {
      return this.$emit('change', {
        floatingip: {
          floating_network_id: this.$store.state.moduleStack.networks.find(x => x.name === event)
            .id,
        },
      });
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "disk": "Источник диска",
      "error": "Свободные порты закончились",
      "type": "Выделить внешний IP из:",
      "description": "Описание"
    },
    "error": "Не верный формат",
    "success": "Плавающий IP успешно выделен.",
    "port": {
      "label": "IP-адрес"
    },
    "placeholder": "Выберите публичную сеть",
    "cost": "Стоимость одного плавающего IP составит %{msg} / час",
    "sure": {
      "confirm": "Увеличить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;
  &-down {
    margin-bottom: 10px;
  }

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
